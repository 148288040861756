import React from 'react'; 
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./pages"
import CommingSoon from './pages/commingSoon';
import About from './pages/about';
import Contacts from './pages/contacts';
import Portrait from './components/Products/Portrait';
import EventPhoto from './components/Products/EventPhoto';
import PhotoStore from './components/Products/PhotoStore';
import ProductPhoto from './components/Products/ProductPhoto';

import './locales/i18n'; 
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'


function App() {
  return (
    <I18nextProvider i18n={i18next}>
    <div>
      <Router>
            <Navbar >
            </Navbar>
            <div className='page-content'>
            <Routes>
                <Route path="/" element={<CommingSoon />} />      
                {/* <Route path="/" element={<Home />} />       */}
                {/* <Route path={`/${Portrait.id}`} element={<Portrait />} />   
                <Route path={`/${EventPhoto.id}`} element={<EventPhoto />} />   
                <Route path={`/${ProductPhoto.id}`} element={<ProductPhoto />} />        
                <Route path={`/${PhotoStore.id}`} element={<PhotoStore />} />          
                <Route path="/about" element={<About />} />   
                <Route path="/contacts" element={<Contacts />} />     */}
            </Routes>
            </div>
        </Router>
        <footer><div>Copyright © {new Date().getFullYear()} Atna Fram. All rights reserved.</div></footer>
    </div>
    </I18nextProvider>
      
  );
}

export default App;
