import React from "react";
import styled from "styled-components";
import { FaInstagramSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';


const Background = styled.div`
	display: block;
    width:100%;
    height:100vh;
    text-align:center;
    background-image: url('/asserts/back2_hd.jpg');
    background-size: cover;
    background-position: center;
         
    
    font-size: larger;
    font-weight: 700; 
`;

const MainText = styled.div`
    font-size: xx-large;
     margin-top:80px;
     width:100%;  
`;

const Social = styled.div`
    //margin-top:100px;
    width:100%;  
`;

const Instagr = styled(FaInstagramSquare)`
font-size: 2rem;
padding-left:10px;
padding-right:10px;
cursor: pointer;
color: rgb(0,0,0,0.8);
backdrop-filter:saturate(180%) blur(20px);
`;

const Twit = styled(FaSquareXTwitter)`
font-size: 2rem;
padding-left:10px;
padding-right:10px;
cursor: pointer;
color: rgb(0,0,0,0.8);
backdrop-filter:saturate(180%) blur(20px);
`;

 
const CommingSoon = () => {
    const { t } = useTranslation("global");
    return (
        <Background>
            <MainText>{t('common.launching')}</MainText>
            <Social>
                <p>{t('common.follow')}</p>
                <a href='https://www.instagram.com/atna_fram' target="_blank"><Instagr/></a>
                <a href='https://www.twitter.com/atna_fram' target="_blank"><Twit/></a>                
            </Social>
        </Background>
    );
};
 
export default CommingSoon;