import styled from "styled-components";

export const LnList = styled.div`
	display: flex;
	align-items: center;
	margin-right: 60px;
    opacity:100%;
    transition: opacity 0.9s ease-in-out;
    .opened-menu &{
		visibility: hidden;
        opacity:0;
        transition: opacity 0.5s ease-in-out;
    }
`;

export const LnLink = styled.a`
	// background: #808080;
	padding: 3px;
	color: #bdb8b8;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
	
	margin-left: 4px;
	&:hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
		color: #808080;
	}

    &.selected{
		background: #474141;
		color: #ffffff;
        // text-decoration:underline;
        

    }
`;
