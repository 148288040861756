import React from "react";
import Product from "./Product";

import { useTranslation } from 'react-i18next'

const id = "events";

const EventPhoto = (props)=> {
    const { t } = useTranslation("global");
    const name = t("navBar.eventPhoto");

    return (
        <Product mode={props.mode} id={id} displayName={name} />
    )
}
EventPhoto.id = id;

export default EventPhoto