import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
	background: rgba(39, 40, 39, 0.8);
	height: 44px;
	display: flex;
	justify-content: space-between;
	/*padding: 0.2rem calc((100vw - 1000px) / 2);*/
	z-index: 12;
    transition: height 0.5s ease-in-out;
    position:fixed;
    backdrop-filter:saturate(180%) blur(20px);
    width:100%;
    @media screen and (max-width: 768px) {
        &.opened-menu {
            position:absolute;
            width:100%;
            top:0;
            left:0;
            height: 100%;
            transition: height 0.5s ease-in-out;
        }
    }
`;
export const Brand = styled(Link)`
	display: flex;
	text-decoration: none;
	cursor: pointer;
`;

export const NavLinkLogo = styled(Link)`
	color: #bdb8b8;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
    position:absolute;

    /*.opened-menu &{
		width:1px;
        transition: all 0.5s ease-in-out;
    }*/
`;

export const NavLink = styled(Link)`
	color: #bdb8b8;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
	&.active {
		color: #000000;
        background-color: #ffffff;
        height: 120%;
	}
`;

export const Bars = styled.div`
	display: none;
	color: #808080;
	@media screen and (max-width: 768px) {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		font-size: 1.8rem;
		cursor: pointer;
        & #line1 {animation: line1-from-45 1 0.5s linear;}
        & #line2 {animation: line2-from-45 1 0.5s linear;}
        .opened-menu & #line1 {
            animation: line1-to-45 1 0.5s linear;
            transform: rotate(45deg) translateX(10px) translatey(-10px) ;
        }

        @keyframes line1-to-45 {
            from {
              transform: rotateX(0deg);
            }
            to {
              transform: rotate(45deg) translateX(10px) translatey(-10px);
            }
          }

          @keyframes line1-from-45 {
            from {
                transform: rotate(45deg) translateX(10px) translatey(-10px);
            }
            to {
                transform: rotateX(0deg);
            }
          }
        

        .opened-menu & #line2 {
            animation: line2-to-45 1 0.5s linear;
            transform: rotate(-45deg) translateX(-20px) translatey(-5px) ;
        }

        @keyframes line2-to-45 {
            from {
              transform: rotateX(0deg);
            }
            to {
              transform: rotate(-45deg) translateX(-20px) translatey(-5px);
            }
          }

          @keyframes line2-from-45 {
            from {
                transform: rotate(-45deg) translateX(-20px) translatey(-5px);
            }
            to {
                transform: rotateX(0deg);
            }
          }

        

	}
`;

export const NavMenu = styled.div`
	display: flex;
	align-items: center;
	/* margin-right: 100px;*/
    margin-left:90px;
	@media screen and (max-width: 768px) {
		position:absolute;
        opacity: 0;
        height: 0px;
        width:0px;
        margin-top:-90px;
        .opened-menu & {  
            visibility: visible;          
            width:100%;
            display: inline-grid;
            margin-left:0px;
            margin-top:50px;
            opacity:100%;
            font-size: x-large;
            font-weight: bold;
            height: 170px;


            transition-property: opacity,margin-top;
            transition-duration: 0.5s;
            transition-timing-function: ease-in-out;            
        }
	}
`;

export const NavBtn = styled.nav`
	display: flex;
	align-items: center;
	margin-right: 24px;
	@media screen and (max-width: 768px) {
		display: none;
        
	}
`;

export const NavBtnLink = styled(Link)`
	border-radius: 4px;
	background: #808080;
	padding: 10px 22px;
	color: #000000;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	margin-left: 24px;
	&:hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
		color: #808080;
	}
`;
