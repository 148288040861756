import React from 'react'; 
import logo from './../../asserts/logo.svg';
import LanguageSwitcher from '../LanguageSwitcher';
import Portrait from './../Products/Portrait';
import EventPhoto from './../Products/EventPhoto';
import PhotoStore from '../Products/PhotoStore';
import ProductPhoto from '../Products/ProductPhoto';

import { useTranslation } from 'react-i18next'

import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
    NavLinkLogo,
    NavBtnLink,
    Brand,
} from "./NavbarElements";


 
const Navbar = () => {
    const { t } = useTranslation("global");
    var toggleMenu = (el)=>{
        document.querySelector('.App-logo-section').classList.toggle('opened-menu');
        document.querySelector('nav').classList.toggle('opened-menu');
    };

    var closeMenu = (el)=>{
        document.querySelector('.App-logo-section').classList.remove('opened-menu');
        document.querySelector('nav').classList.remove('opened-menu');
    };

    return (
        <>
            <Nav>    
            <div className='App-logo-section'>
            <NavLinkLogo to="/" >
                <img className="App-logo" src={logo}></img>
            </NavLinkLogo> 
            </div>            
                <Bars onClick={toggleMenu}>
                <svg width="30px" height="30px" viewBox="0 0 30 30">
                    <line x1="0" y1="20" x2="20" y2="20" id="line1" stroke="#bdb8b8" />
                    <line x1="0" y1="25" x2="20" y2="25" id="line2" stroke="#bdb8b8" />                    
                  </svg>  
                </Bars>    
                          
                <NavMenu>
                    {/* <NavLink to="/" onClick={closeMenu} >
                        {t("navBar.home")}
                    </NavLink> */}
                    {/* <NavLink to={`/${Portrait.id}`}  onClick={closeMenu} >
                        {t("navBar.portrait")}
                    </NavLink>
                    <NavLink to={`/${EventPhoto.id}`}  onClick={closeMenu} >
                        {t("navBar.eventPhoto")}
                    </NavLink>
                    <NavLink to={`/${ProductPhoto.id}`}  onClick={closeMenu} >
                        {t("navBar.productPhoto")}
                    </NavLink>
                    <NavLink to={`/${PhotoStore.id}`}  onClick={closeMenu} >
                        {t("navBar.photoStore")}
                    </NavLink>
                    <NavLink to="/about"  onClick={closeMenu}>
                        {t("navBar.about")}
                    </NavLink>
                    <NavLink to="/contacts"  onClick={closeMenu}>
                        {t("navBar.contacts")}
                    </NavLink> */}
                </NavMenu>
                <LanguageSwitcher/>
            </Nav>                
        </>
    );
};


export default Navbar;