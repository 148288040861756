import React from "react";
import Product from "./Product";

import { useTranslation } from 'react-i18next';
import config from './../../asserts/products/portrait/config.json';

const id = "portrait";

const Portrait = (props)=> {
    const { t } = useTranslation("global");
    const displayName = t("navBar.portrait");

    return (
        <>
        <Product mode={props.mode} id={id} displayName={displayName} config={config} >
        <div>sdfgsdfgdfg dsfg sdf</div>
        </Product>
        </>
    )
}
Portrait.id = id;

export default Portrait