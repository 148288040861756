import React,{useState} from 'react'; 
import { LnList,LnLink } from './Elements';
import { LANGUAGES } from "../../locales/constants";

import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {

    const [ currentLanguage, setLanguage ] = useState('en'); 
    const { i18n } = useTranslation("global");

    const changeLanguage = (e) => { 

        const value = e.target.dataset.value;
        e.target.parentNode.querySelectorAll('a').forEach(el => {
            el.classList.remove('selected');
        });

        e.target.classList.add('selected');

        i18n 
        .changeLanguage(value) 
        .then(() => setLanguage(value)) 
        .catch(err => console.log(err)); 
    };    


    return (
        <>
                <LnList>
                    {LANGUAGES.map(({ code, label }) => (
                        <LnLink key={code} data-value={code} onClick={changeLanguage} title={label} className={ code === currentLanguage? 'selected':'' } >
                            {code}
                        </LnLink>
                                ))}
                </LnList>

        </>
    );
};
export default LanguageSwitcher;
