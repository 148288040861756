import React from "react";
import { useTranslation } from 'react-i18next';


const Product = (props)=> {
    const { t } = useTranslation("global");
    const config = (props.config||{})[props.mode]|| (props.config||{})["default"] || {};

    const desc = t(config['description']|| '');

    return (
        <>
        <div className={ props.mode ==='preview'? "product-item":"page" }>
            <h3>display name:{props.displayName}</h3>
            <div dangerouslySetInnerHTML={{ __html: desc }} />
            <div>
                id:{props.id}
                mode:{props.mode}
            </div>
            <div>
                conf:{JSON.stringify(props.config)}
            </div>
            <div><a href={`/${props.id}`}>More info</a></div>
        </div>
        </>
    )
}

export default Product