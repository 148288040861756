import i18n from 'i18next'; 
import {initReactI18next} from 'react-i18next'; 
import LanguageDetector from 'i18next-browser-languagedetector';
import en_res from './en.json'; 
import ru_res from './ru.json'; 

const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng'
  }
 
i18n.use(LanguageDetector).use(initReactI18next).init({
    interpolation: { escapeValue: false },
    detection: options,
    fallbackLng: 'en',
    resources: {
        en: { global: en_res, },
        ru: { global: ru_res, },
    },
 })
  
export default i18n; 