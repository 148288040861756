import React from "react";
import Product from "./Product";

import { useTranslation } from 'react-i18next'

const id = "productPhoto";

const ProductPhoto = (props)=> {
    const { t } = useTranslation("global");
    const displayName = t("navBar.productPhoto");

    return (
        <Product mode={props.mode} id={id} displayName={displayName} />
    )
}
ProductPhoto.id = id;

export default ProductPhoto